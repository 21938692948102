import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Col, Form, Row, Select, Tabs } from 'antd';
import Button from '../../UI/buttons/Button';

import { ClientFeeTemplate } from '../../types';
import { CaretDownOutlined } from '@ant-design/icons';

import {
  HIREPORT_DEFAULT_COMPENSATION_TYPE,
  HIREPORT_DEFAULT_GUARANTEE_PERIOD,
} from '../../constants/recruiters';
import FormItem from '../../components/form/FormItem';
import Input from '../../UI/inputs/Input';
import SelectInput from '../../UI/inputs/Select';
import { FEE_TYPES } from '../../components/recruiter/AddCompanyRecruiterForm';
import {
  ActionLink,
  ActionWrapper,
  DeleteFileIcon,
  FileName,
  FileUploader,
  StyledUploader,
  UploadAction,
} from '../../components/recruitment_management/RecruiterEditAgreement';
import { ReactComponent as DownloadIcon } from '../../icons/download.svg';
import { RcFile } from 'antd/lib/upload';
import Tab from '../../UI/Tab';
import { useGetEmploymentTypeNameById } from '../../hooks/useEmploymentTypes';
import Textarea from '../../UI/inputs/Textarea';

export type MasterContractFormProps = {
  isLoading: boolean;
  initialData?: ClientFeeTemplate;
  submitLabel?: string;
  onSubmit: (data: FormData) => void;
  onContractDelete?: () => void;
  onBack?: () => void;
  onCancel?: () => void;
};

const { TabPane } = Tabs;

const FormWrapper = styled.div`
  padding: 2rem;
  background: #ffffff;
  border-radius: ${({ theme }) => theme.radius};
  @media (max-width: 576px) {
    padding: 0 1rem;
  }
`;

const FeeSetupsHeading = styled(Form)`
  width: 100%;
  font-family: Lato;
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
`;

const StyledForm = styled(Form)`
  margin-top: 2.5rem;
  width: 100%;
`;

const FormSubtitle = styled.h2`
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #061c2e;
  margin: 0.5rem 0;
`;

export const PAYMENT_TERM_TYPES = [
  {
    name: '30 Days',
    value: 30,
  },
  {
    name: '60 Days',
    value: 60,
  },
  {
    name: '90 Days',
    value: 90,
  },
];

export const PAYMENT_TERM_START_TYPES = [
  {
    name: 'After signing contract',
    value: 'After signing contract',
  },
  {
    name: 'After candidate start date',
    value: 'After candidate start date',
  },
];

const MasterContractForm = ({ isLoading, initialData, submitLabel = 'SUBMIT', onSubmit, onContractDelete, onBack, onCancel }: MasterContractFormProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  useState<ClientFeeTemplate | null>(null);
  const [file, setFile] = useState(initialData?.contract ?? '');
  const [newFileUploading, setNewFileUploading] = useState(false);
  const getEmploymentTypeNameById = useGetEmploymentTypeNameById();

  const initialFormValues = {
    name: initialData?.name,
    compensation_type:
      initialData?.compensation_type ??
      HIREPORT_DEFAULT_COMPENSATION_TYPE,
    compensation_type_tooltip: initialData?.compensation_type_tooltip,
    guarantee_period:
      initialData?.guarantee_period ?? HIREPORT_DEFAULT_GUARANTEE_PERIOD,
    payment_terms: initialData?.payment_terms,
    restitution: initialData?.restitution,
    restitution_payment_terms: initialData?.restitution_payment_terms,
    payment_terms_start: initialData?.payment_terms_start,
    replacement_time: initialData?.replacement_time,
    custom_restitution_agreement:
      initialData?.custom_restitution_agreement,
    other_information: initialData?.other_information,
    exclusivity_for_replacement:
      initialData?.exclusivity_for_replacement === true
        ? 'Yes'
        : initialData?.exclusivity_for_replacement === false
          ? 'No'
          : null,
    et_details_freelance: initialData?.et_details_freelance,
    et_details_ctp: initialData?.et_details_ctp,
    et_gda_ctp: initialData?.et_gda_ctp,
    et_cao_ctp: initialData?.et_cao_ctp,
  };

  const handleUploadFile = (file: RcFile): boolean => {
    setFile(file.name);
    setNewFileUploading(true);
    return true;
  };

  const handleFileDelete = (e: React.MouseEvent<SVGSVGElement>) => {
    e.stopPropagation();
    setFile('');
    form.setFieldsValue({ contract: null });
  };

  const onUpdate = (values: any) => {
    const templateValues = Object.fromEntries(
      Object.entries(values).map(([key, value]) => {
        if (value === '' || value === undefined) {
          return [key, null];
        }
        return [key, value];
      }),
    ) as ClientFeeTemplate;

    const data = new FormData();

    data.append('name', templateValues.name);
    data.append('compensation_type', templateValues.compensation_type);
    data.append(
      'compensation_type_tooltip',
      String(templateValues.compensation_type_tooltip),
    );
    data.append('guarantee_period', String(templateValues.guarantee_period));
    data.append('payment_terms', String(templateValues.payment_terms));
    data.append('restitution', String(templateValues.restitution));
    data.append(
      'restitution_payment_terms',
      String(templateValues.restitution_payment_terms),
    );
    data.append(
      'custom_restitution_agreement',
      String(templateValues.custom_restitution_agreement),
    );
    data.append('other_information', String(templateValues.other_information));
    data.append(
      'payment_terms_start',
      String(templateValues.payment_terms_start),
    );
    data.append('replacement_time', String(templateValues.replacement_time));
    data.append(
      'exclusivity_for_replacement',
      String(templateValues.exclusivity_for_replacement),
    );
    // These fields need to be defaulted to null because of the way antd's tab controls work:
    // if the user hasn't yet visited the tab, the field will be missing, i.e. undefined
    // and will be serialized as the string 'undefined' by the String() function.
    data.append('et_details_freelance', String(templateValues.et_details_freelance || null));
    data.append('et_details_ctp', String(templateValues.et_details_ctp || null));
    data.append('et_gda_ctp', String(templateValues.et_gda_ctp || null));
    data.append('et_cao_ctp', String(templateValues.et_cao_ctp || null));
    if (values.contract?.file?.originFileObj) {
      data.append('contract', values.contract.file.originFileObj);
    }

    onSubmit(data);
  };

  return (
    <FormWrapper>
      <FeeSetupsHeading>{initialData?.name}</FeeSetupsHeading>
      <StyledForm
        scrollToFirstError={true}
        layout="vertical"
        name="invitation"
        onFinish={onUpdate}
        form={form}
        initialValues={initialFormValues}
      >
        <div style={{ width: '100%', borderBottom: '1px solid #DAE5EC' }}>
          <FormSubtitle style={{ fontSize: '18px', paddingBottom: '1rem' }}>
            {t('FEE_TEMPLATE_NAME')}
          </FormSubtitle>
        </div>
        <Row gutter={16} style={{ marginTop: '1rem' }}>
          <Col span={10}>
            <FormItem name="name" className="no-margin">
              <Input
                placeholder={t('MASTER_CONTRACT_NAME')}
                maxLength={25}
                size="large"
                type="text"
              />
            </FormItem>
          </Col>
        </Row>
        <Tab defaultActiveKey="1" className="mt-5">
          <TabPane tab={t('FEE_ET_TYPE', { employmentType: getEmploymentTypeNameById('permanent') })} key="1">
            <div
              style={{
                width: '100%',
                marginTop: '2.5rem',
                borderBottom: '1px solid #DAE5EC',
              }}
            >
              <FormSubtitle style={{ fontSize: '16px', paddingBottom: '1rem' }}>
                {t('FEE_SETUP_FEE_INFORMATION')}
              </FormSubtitle>
            </div>
            <Row gutter={16} style={{ marginTop: '1rem' }}>
              <Col span={8}>
                <FormItem
                  label="FEE STRUCTURE"
                  name="compensation_type"
                  className="no-margin"
                >
                  <SelectInput
                    size="large"
                    suffixIcon={<CaretDownOutlined style={{ color: '#000' }} />}
                  >
                    {FEE_TYPES.map((fee, index) => (
                      <Select.Option value={fee.fee_value} key={index}>
                        {fee.fee_name}
                      </Select.Option>
                    ))}
                  </SelectInput>
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem
                  label="FEE DETAILS"
                  name="compensation_type_tooltip"
                  className="no-margin"
                >
                  <Input
                    type="number"
                    placeholder={t('JOB_FEE_DETAILS_PLACEHOLDER')}
                    maxLength={70}
                    size="large"
                  />
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem
                  label={t('GUARANTEE_PERIOD')}
                  name="guarantee_period"
                  className="no-margin"
                >
                  <Input
                    placeholder={t('GUARANTEE_PERIOD_PLACEHOLDER')}
                    maxLength={70}
                    size="large"
                    type="number"
                  />
                </FormItem>
              </Col>
            </Row>
            <div
              style={{
                width: '100%',
                marginTop: '2.5rem',
                borderBottom: '1px solid #DAE5EC',
              }}
            >
              <FormSubtitle style={{ fontSize: '16px', paddingBottom: '1rem' }}>
                {t('FEE_SETUP_RESTITUTION')}
              </FormSubtitle>
            </div>
            <Row gutter={16} style={{ marginTop: '1rem' }}>
              <Col span={8}>
                <FormItem
                  label={t('JOB_RESTITUTION')}
                  name="restitution"
                  className="no-margin"
                >
                  <Input
                    placeholder={t('COMPANY_AGREEMENT_RESTITUTION_PLACEHOLDER')}
                    maxLength={70}
                    size="large"
                    type="number"
                  />
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem
                  label={t('JOB_REPLACEMENT_TIME')}
                  name="replacement_time"
                  className="no-margin"
                >
                  <Input
                    placeholder={t(
                      'COMPANY_AGREEMENT_REPLACEMENT_TIME_PLACEHOLDER',
                    )}
                    maxLength={70}
                    size="large"
                    type="number"
                  />
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem
                  label={t('JOB_EXCLUSIVITY_FOR_REPLACEMENT')}
                  name="exclusivity_for_replacement"
                  className="no-margin"
                >
                  <SelectInput
                    allowClear
                    placeholder={t(
                      'COMPANY_AGREEMENT_EXCLUSIVITY_FOR_REPLACEMENT_PLACEHOLDER',
                    )}
                    size="large"
                    suffixIcon={<CaretDownOutlined style={{ color: '#000' }} />}
                  >
                    {['Yes', 'No'].map((val, index) => (
                      <Select.Option value={val} key={index}>
                        {val}
                      </Select.Option>
                    ))}
                  </SelectInput>
                </FormItem>
              </Col>
            </Row>
            <Row gutter={16} style={{ marginTop: '1rem' }}>
              <Col span={24}>
                <FormItem
                  label={t('JOB_CUSTOM_RESTITUTION_AGREEMENT')}
                  name="custom_restitution_agreement"
                  className="no-margin"
                >
                  <Input maxLength={70} size="large" type="text" />
                </FormItem>
              </Col>
            </Row>
            <div
              style={{
                width: '100%',
                marginTop: '2.5rem',
                borderBottom: '1px solid #DAE5EC',
              }}
            >
              <FormSubtitle style={{ fontSize: '16px', paddingBottom: '1rem' }}>
                {t('FEE_SETUP_PAYMENT_TERMS')}
              </FormSubtitle>
            </div>

            <Row gutter={16} style={{ marginTop: '1rem' }}>
              <Col span={8}>
                <FormItem
                  label={t('JOB_PAYMENT_TERMS')}
                  name="payment_terms"
                  className="no-margin"
                >
                  <SelectInput
                    size="large"
                    suffixIcon={<CaretDownOutlined style={{ color: '#000' }} />}
                  >
                    {PAYMENT_TERM_TYPES.map((payment_term, index) => (
                      <Select.Option value={payment_term.value} key={index}>
                        {payment_term.name}
                      </Select.Option>
                    ))}
                  </SelectInput>
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem
                  label={t('JOB_PAYMENT_TERMS_START')}
                  name="payment_terms_start"
                  className="no-margin"
                >
                  <SelectInput
                    size="large"
                    suffixIcon={<CaretDownOutlined style={{ color: '#000' }} />}
                  >
                    {PAYMENT_TERM_START_TYPES.map((payment_term, index) => (
                      <Select.Option value={payment_term.value} key={index}>
                        {payment_term.name}
                      </Select.Option>
                    ))}
                  </SelectInput>
                </FormItem>
              </Col>
            </Row>
            <Row gutter={16} style={{ marginTop: '1rem' }}>
              <Col span={8}>
                <FormItem
                  label={t('JOB_RESTITUTION_PAYMENT_TERMS')}
                  name="restitution_payment_terms"
                  className="no-margin"
                >
                  <SelectInput
                    size="large"
                    suffixIcon={<CaretDownOutlined style={{ color: '#000' }} />}
                  >
                    {PAYMENT_TERM_TYPES.map((payment_term, index) => (
                      <Select.Option value={payment_term.value} key={index}>
                        {payment_term.name}
                      </Select.Option>
                    ))}
                  </SelectInput>
                </FormItem>
              </Col>
            </Row>
            <div
              style={{
                width: '100%',
                marginTop: '2.5rem',
                borderBottom: '1px solid #DAE5EC',
              }}
            >
              <FormSubtitle style={{ fontSize: '16px', paddingBottom: '1rem' }}>
                {t('JOB_OTHER_INFORMATION')}
              </FormSubtitle>
            </div>
            <Row gutter={16} style={{ marginTop: '1rem' }}>
              <Col span={24}>
                <FormItem name="other_information" className="no-margin">
                  <Input maxLength={70} size="large" type="text" />
                </FormItem>
              </Col>
            </Row>
          </TabPane>
          <TabPane tab={t('FEE_ET_TYPE', { employmentType: getEmploymentTypeNameById('freelance') })} key="2">
            <div
              style={{
                width: '100%',
                marginTop: '2.5rem',
                borderBottom: '1px solid #DAE5EC',
              }}
            >
              <FormSubtitle style={{ fontSize: '16px', paddingBottom: '1rem' }}>
                {t('FEE_SETUP_FEE_INFORMATION')}
              </FormSubtitle>
            </div>
            <Row gutter={16} style={{ marginTop: '1rem' }}>
              <Col span={8}>
                <FormItem
                  label={t('JOB_ET_DETAILS', { employmentType: getEmploymentTypeNameById("freelance") })}
                  name="et_details_freelance"
                  className="no-margin"
                >
                  <Input
                    maxLength={70}
                    size="large"
                    type="text"
                  />
                </FormItem>
              </Col>
            </Row>
          </TabPane>
          <TabPane tab={t('FEE_ET_TYPE', { employmentType: getEmploymentTypeNameById('ctp') })} key="3">
            <div
              style={{
                width: '100%',
                marginTop: '2.5rem',
                borderBottom: '1px solid #DAE5EC',
              }}
            >
              <FormSubtitle style={{ fontSize: '16px', paddingBottom: '1rem' }}>
                {t('FEE_SETUP_FEE_INFORMATION')}
              </FormSubtitle>
            </div>
            <Row gutter={16} style={{ marginTop: '1rem' }}>
              <Col span={8}>
                <FormItem
                  label={t('JOB_ET_DETAILS', { employmentType: getEmploymentTypeNameById("ctp") })}
                  name="et_details_ctp"
                  className="no-margin"
                >
                  <Input
                    maxLength={70}
                    size="large"
                    type="text"
                  />
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem
                  label={t('JOB_ET_CAO', { employmentType: getEmploymentTypeNameById("ctp") })}
                  name="et_cao_ctp"
                  className="no-margin"
                >
                  <Input
                    maxLength={70}
                    size="large"
                    type="text"
                  />
                </FormItem>
              </Col>
            </Row>
            <Row gutter={16} style={{ marginTop: '1rem' }}>
              <Col span={16}>
                <FormItem
                  label={t('JOB_ET_GDA', { employmentType: getEmploymentTypeNameById("ctp") })}
                  name="et_gda_ctp"
                  className="no-margin"
                >
                  <Textarea
                    autoSize={{ minRows: 3 }}
                  />
                </FormItem>
              </Col>
            </Row>
          </TabPane>
        </Tab>
        <div
          style={{
            width: '100%',
            marginTop: '2.5rem',
            borderBottom: '1px solid #DAE5EC',
          }}
        >
          <FormSubtitle style={{ fontSize: '16px', paddingBottom: '1rem' }}>
            {t('RECRUITER_CONTRACT')}
          </FormSubtitle>
        </div>
        <Row gutter={16} style={{ marginTop: '1rem' }}>
          <>
            {initialData?.contract && file && !newFileUploading ? (
              <div style={{ display: 'flex' }}>
                <ActionWrapper>
                  <ActionLink
                    href={file as string}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <DownloadIcon />
                    {t('COMPANY_AGREEMENT_DOWNLOAD_CONTRACT')}
                  </ActionLink>
                </ActionWrapper>
                <UploadAction>
                  <button
                    style={{
                      border: 'none',
                      display: 'flex',
                      backgroundColor: 'transparent',
                    }}
                  >
                    <DeleteFileIcon onClick={handleFileDelete} />
                  </button>
                </UploadAction>
              </div>
            ) : (
              <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 24 }}
                lg={{ span: 12 }}
              >
                <FormItem
                  label={t('COMPANY_AGREEMENT_CONTRACT')}
                  name="contract"
                >
                  <StyledUploader
                    name="file"
                    showUploadList={false}
                    beforeUpload={handleUploadFile}
                    customRequest={() => void 0}
                  >
                    <FileUploader>
                      <FileName>{file}</FileName>
                      <UploadAction>
                        <Button type={'default'} size="small">
                          {t('CHOOSE_FILE')}
                        </Button>

                        {file && (
                          <DeleteFileIcon onClick={handleFileDelete} />
                        )}
                      </UploadAction>
                    </FileUploader>
                  </StyledUploader>
                </FormItem>
              </Col>
            )}
          </>
        </Row>
        <Row
          gutter={16}
          style={{
            width: '100%',
            display: 'flex',
            marginTop: '2rem',
          }}
        >
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <div>
              {onBack && <Button
                style={{ marginRight: '1rem' }}
                type="default"
                onClick={() => { onBack() }}
              >
                {t('BACK')}
              </Button>}
            </div>
            <div style={{ display: 'flex' }}>
              {onContractDelete && <div>
                <Button
                  style={{
                    marginRight: '1rem',
                    borderColor: '#ff4d4f',
                    color: '#ff4d4f',
                  }}
                  onClick={() => { onContractDelete() }}
                >
                  {t('DELETE_TITLE')}
                </Button>
              </div>}
              {onCancel && <FormItem>
                <Button
                  style={{ marginRight: '1rem' }}
                  onClick={() => { onCancel() }}
                >
                  {t('CANCEL_TEXT')}
                </Button>
              </FormItem>
              }
              <FormItem>
                <Button type="primary" htmlType="submit" loading={isLoading}>
                  {t(submitLabel)}
                </Button>
              </FormItem>
            </div>
          </div>
        </Row>
      </StyledForm>
    </FormWrapper>
  );
};

export default MasterContractForm;
